table {
  tbody {
    tr:first-of-type {
      td {
        border-top: none;
      }
    }
  }
}

/* Adds a right hand shadow on the table */
.table-responsive {
  @include media-breakpoint-down(sm) {
    position: relative;
    display: flex;

    &:after {
      content: '';
      display: block;
      position: sticky;
      width: 20px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
      margin-left: -20px;
      z-index: 2;
      pointer-events: none;
      flex-shrink: 0;
      right: 0;
    }
  }
}

table {
  thead {
    th {
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $gray-600;
    }
  }
}

/* Hides all columns except the first */
table.mobile-list-table {
  @include media-breakpoint-down(sm) {
    th, td {
      display: none;
    }

    td.mobile-list-table-keep {
      display: table-cell;
      position: relative;
      cursor: pointer;

      &:before {
        content:"\203A";
        color: $gray-400;
        font-size: 2rem;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-56%);
      }
    }
  }
}

