@import "bootstrap";

/**
  * Import Components
  */
@import "components/header";
@import "components/forms";
@import "components/footer";
@import "components/mobile-tab-bar";
@import "components/tables";

/**
 * Backgrounds
 */
.bg-gray-100 { background-color: $gray-100; }
.bg-gray-200 { background-color: $gray-200; }
.bg-gray-300 { background-color: $gray-300; }
.bg-gray-400 { background-color: $gray-400; }
.bg-gray-500 { background-color: $gray-500; }
.bg-gray-600 { background-color: $gray-600; }
.bg-gray-700 { background-color: $gray-700; }
.bg-gray-800 { background-color: $gray-800; }
.bg-gray-900 { background-color: $gray-900; }

/**
  * Generic Styles
  */
body, html {
  height: 100%;
  width: 100%;
  display: block;

  * {
    outline: none !important;
  }
}
